import memoize from "lodash/memoize"
import { getGatsbyImageData } from "@imgix/gatsby"

import { isProduction } from "./_env"
import { Layout } from "gatsby-plugin-image"
import { ImageType } from "@srcTypes"

/*
  Image/Imgix utilities
  Note: Imgix is only used for production. On Staging and Local the files will be referenced directly.
*/
const IMGIX_DOMAIN: string = "https://hs-marketing.imgix.net"

export const getImgixImageData = memoize(
  (imgPath: string, width: number, aspectRatio: number, layout: Layout = "constrained") => (
    isProduction() ?
      getGatsbyImageData({
        aspectRatio,
        imgixParams: {
          auto: "format,compress",
          placeholder: "dominantColor",
        },
        layout,
        src: `${IMGIX_DOMAIN}${imgPath}`,
        width,
      }) : {
        images: {
          sources: [],
          fallback: {
            src: imgPath,
            sizes: `(min-width: ${width}px) ${width}px, 100vw`,
          }
        },
        layout,
        width,
        height: width / aspectRatio,
      }
  )
  // Hash function for memoize to size duplicate image paths properly
  , (imgPath: string, width: number, aspectRatio: number, layout: Layout = "constrained") => `${imgPath}${width}${aspectRatio}${layout}`
)

export const getImgixUrl = (imgPath: string, width?: number, height?: number, dpr: number = 1): string =>
  !isProduction() ?
    `${IMGIX_DOMAIN}${imgPath}?auto=compress,format&fit=scale${width ? `&w=${width}` : ""}${height ? `&h=${height}` : ""}&dpr=${dpr}` :
    imgPath

type ImgixAttrs = {
  src: string,
  srcSet?: string,
}

export const getImgixAttrs = (imgPath: string, width?: number, height?: number): ImgixAttrs => (
  (!isProduction() || imgPath.includes(".gif") || imgPath.includes(".svg")) ?
    {
      src: imgPath
    } : {
      src: getImgixUrl(imgPath, width, height, 2),
      srcSet: `${getImgixUrl(imgPath, width, height, 1)}, ${getImgixUrl(imgPath, width, height, 2)} 2x, ${getImgixUrl(imgPath, width, height, 3)} 3x`,
    }
)

export const getImageTypeData = memoize(
  (imgPath: string, width: number, aspectRatio: number, layout: Layout = "constrained"): ImageType => ({
    imgixImage: {
      gatsbyImageData: getImgixImageData(imgPath, width, aspectRatio, layout)
    },
  })
  // Hash function for memoize to size duplicate image paths properly
  , (imgPath: string, width: number, aspectRatio: number, layout: Layout = "constrained") => `${imgPath}${width}${aspectRatio}${layout}`
)
